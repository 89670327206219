import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ApiErrors } from 'client/utils'
import { EMAIL_MAX_LENGTH } from 'atw-shared/utils'
import { isEmailValid } from 'atw-shared/validation'
import { AppFormInput } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
  apiErrors: ApiErrors
}

const NewEmailInput = ({ isLoading, apiErrors }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error =
    errors?.email?.message ||
    (apiErrors?.email ? t(`apiValidationErrors.${apiErrors.email}`) : '')

  return (
    <Controller
      control={control}
      name="email"
      rules={{
        required: t('common.validation.required'),
        validate: value => isEmailValid(value) || t('common.validation.invalid'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('common.email.label')}
            placeholder={t('common.email.placeholder')}
            maxLength={EMAIL_MAX_LENGTH}
            autoComplete="off"
            spellCheck={false}
            disabled={isLoading}
            error={String(error || '')}
          />
        )
      }}
    />
  )
}

export default NewEmailInput
