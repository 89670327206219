import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { READ_QUESTIONS_MAX } from 'atw-shared/utils'
import { Direction } from 'client/utils'
import { useAppContext, usePage } from 'client/hooks'
import { AppButton, AppGroup } from 'client/ui-building-blocks'
import { TData as TQuestionsData } from 'client/api/useGetQuestions/useGetQuestions'

type Props = {
  questionId: string
  questionsData?: TQuestionsData
  isInReanswerMode: boolean
  isDisabled: boolean
}

const Navigation = ({
  questionId,
  questionsData,
  isInReanswerMode,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const [currentIndex, setCurrentIndex] = useState<number>(-1)

  const { navigateTo } = usePage()
  const { browseQuestionsPageState } = useAppContext()
  const { pageNo } = browseQuestionsPageState

  useEffect(() => {
    if (pathname === '/home') {
      setCurrentIndex(questionsData.data.findIndex(q => q._id === questionId))
    } else if (pathname === '/browse') {
      setCurrentIndex(
        questionsData.data.findIndex(q => q._id === questionId) +
          (pageNo - 1) * READ_QUESTIONS_MAX
      )
    }
  }, [pathname, pageNo, questionId, questionsData?.data])

  if (currentIndex === -1 || !questionsData || questionsData.count <= 1) return null

  const onNavigate = (direction: Direction) => {
    if (isDisabled) {
      return
    }

    document.getElementById('submit-form-reset-btn')?.click()

    const currentIndex = questionsData.data.findIndex(q => q._id === questionId)
    let newIndex: number

    if (direction === Direction.Prev) {
      if (currentIndex > 0) {
        newIndex = currentIndex - 1
      } else {
        newIndex = questionsData.data.length - 1
      }
    } else {
      if (currentIndex < questionsData.data.length - 1) {
        newIndex = currentIndex + 1
      } else {
        newIndex = 0
      }
    }

    navigateTo({
      path: pathname,
      query: { questionId: questionsData.data[newIndex]._id.toString() },
      preventScroll: true,
    })
  }

  const onNavPrevClick = () => {
    onNavigate(Direction.Prev)
  }

  const onNavNextClick = () => {
    onNavigate(Direction.Next)
  }

  const totalPages = Math.ceil(questionsData.count / READ_QUESTIONS_MAX)

  const current = currentIndex + 1
  const total = questionsData.count

  if (!questionsData?.data?.find(q => q._id.toString() === questionId)) {
    return null
  }

  return (
    <AppGroup>
      <AppButton
        variant="blank"
        iconName="prev"
        size="small"
        disabled={isInReanswerMode}
        onClick={onNavPrevClick}
        id="question-nav-prev"
      />
      <AppButton
        variant="blank"
        size="small"
        text={
          totalPages > 1
            ? t('question.nav.info_page', { pageNo, current, total })
            : t('question.nav.info', { current, total })
        }
        disabled={isInReanswerMode}
      />
      <AppButton
        variant="blank"
        iconName="next"
        size="small"
        disabled={isInReanswerMode}
        onClick={onNavNextClick}
        id="question-nav-next"
      />
    </AppGroup>
  )
}

export default Navigation
