import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { UseFormReset } from 'react-hook-form'
import { useSwipeable } from 'react-swipeable'
import { useQueryClient } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'

import { AppMsgType } from 'atw-shared/utils'
import { MODAL_DIALOG_ENTER_MS, QuestionSubmitFormValues } from 'client/utils'
import { useAppContext, useModalDialogContext, usePage } from 'client/hooks'
import { copyToClipboard, fixScrollPosition } from 'client/helpers'
import { useGetQuestion, useCreateAnswer, useUpdateAnswer } from 'client/api'
import { TData as TQuestionsData } from 'client/api/useGetQuestions/useGetQuestions'

import {
  getAccountStatus,
  isAccountConfirmed,
  startQuestionSocket,
  stopQuestionSocket,
  displaySnackBar,
} from 'client/services'

import {
  AppButton,
  AppDivider,
  AppGroup,
  AppLoader,
  AppModalDialog,
} from 'client/ui-building-blocks'

import ReportQuestionModal from './ReportQuestionModal/report-question-modal'
import DeleteQuestionModal from './DeleteQuestionModal/delete-question-modal'
import TerminateQuestionModal from './TerminateQuestionModal/terminate-question-modal'

import ContextMenu from './ContextMenu/context-menu'
import Navigation from './Navigation/navigation'
import RandomNavigation from './RandomNavigation/random-navigation'
import TitleBox from './TitleBox/title-box'
import SubmitForm from './SubmitForm/submit-form'
import VotingResults from './VotingResults/voting-results'

import styles from './question.module.scss'

type Props = {
  questionId: string
  questionsData?: TQuestionsData
  isInModal?: boolean
  minReqTimeMs?: number
}

const Question = ({ questionId, questionsData, isInModal = false }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { navigateTo } = usePage()
  const { toggleNoAccessModal, toggleQuestionModal, showQuestionModal } =
    useModalDialogContext()

  const { authUser, isInReanswerMode, setIsInReanswerMode } = useAppContext()
  const accountStatus = getAccountStatus(authUser)

  const {
    data: questionData,
    refetch: fetchQuestion,
    isLoading: isLoadingQuestion,
    isFetching: isFetchingQuestion,
  } = useGetQuestion({ questionId })

  const refetchQuestion = () => {
    return fetchQuestion().then(res => {
      setIsInReanswerMode(false)
      setIsSubmitting(false)
      if (res.isError && res.error?.msg) {
        toggleQuestionModal(false)
        displaySnackBar(res.error.msg)
        setTimeout(() => {
          navigateTo({ path: '/home' })
        })
      } else {
        fixScrollPosition(showQuestionModal)
      }
    })
  }

  // main question fetch
  useEffect(() => {
    const run = async () => {
      if (isInModal && isLoadingQuestion) {
        await new Promise(resolve => setTimeout(resolve, MODAL_DIALOG_ENTER_MS))
      }
      refetchQuestion()
    }

    if (questionId) {
      run()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId])

  const { mutateAsync: createAnswer, isLoading: isCreatingAnswer } = useCreateAnswer()
  const { mutateAsync: updateAnswer, isLoading: isUpdatingAnswer } = useUpdateAnswer()

  // mobile swipe handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      document.getElementById('question-nav-next')?.click()
    },
    onSwipedRight: () => {
      document.getElementById('question-nav-prev')?.click()
    },
  })

  // onSubmit handler
  const onSubmit = async (
    formValues: QuestionSubmitFormValues,
    reset: UseFormReset<QuestionSubmitFormValues>
  ): Promise<unknown> => {
    if (isFetchingQuestion || isCreatingAnswer || isUpdatingAnswer) return

    if (isAccountConfirmed(accountStatus)) {
      if (!values(formValues.votes).some(selected => selected)) return

      const onSuccess = () => {
        refetchQuestion().then(() => {
          reset()
        })
      }

      const onError = err => {
        if (err?.msg) {
          setIsInReanswerMode(false)
          setIsSubmitting(false)
          toggleQuestionModal(false)
          displaySnackBar(err.msg)
        }
      }

      setIsSubmitting(true)

      if (!isInReanswerMode) {
        return createAnswer({ questionId, formValues }).then(onSuccess).catch(onError)
      } else {
        return updateAnswer({ questionId, formValues }).then(onSuccess).catch(onError)
      }
    } else {
      setIsSubmitting(false)
      toggleNoAccessModal(true)
    }
  }

  const question = questionData?.data[0]

  // managing sockets
  useEffect(() => {
    if (
      !isInReanswerMode &&
      question?.answeredStats &&
      !isEmpty(question?.answeredStats)
    ) {
      startQuestionSocket(queryClient, question._id.toString())
    } else {
      stopQuestionSocket()
    }
  }, [queryClient, question?._id, question?.answeredStats, isInReanswerMode])

  const useSwipeHandlers = ['/home', '/browse', '/random'].includes(pathname)

  const displayVotingResults =
    authUser &&
    question &&
    (question.terminatedAt ||
      (!isInReanswerMode && question.answeredStats?.byRequestor.length > 0))

  const isDisabled =
    isLoadingQuestion || isFetchingQuestion || isCreatingAnswer || isUpdatingAnswer

  const handleClose = () => {
    navigateTo({ path: pathname, preventScroll: true })
  }

  const onCopyLinkBtnClick = () => {
    const href = window.location.origin + '/question?_id=' + question._id
    copyToClipboard(href)
    displaySnackBar({
      type: AppMsgType.Info,
      text: t('question.contextMenu.msgs.linkCopied'),
    })
  }

  const onOpenBtnClick = () => {
    const href = window.location.origin + '/question?_id=' + question._id
    window.open(href)
  }

  const Header = (
    <div className={styles.topBar}>
      <div>
        <ContextMenu
          question={question}
          isInReanswerMode={isInReanswerMode}
          setIsInReanswerMode={setIsInReanswerMode}
          isDisabled={isDisabled}
        />
      </div>
      <div>
        {pathname === '/random' ? (
          <>
            <RandomNavigation
              isSubmitting={isSubmitting}
              isFetchingQuestion={isFetchingQuestion}
              isLoadingQuestion={isLoadingQuestion}
            />
            <AppDivider size="xsmall" />
          </>
        ) : (
          <Navigation
            questionId={question?._id.toString()}
            questionsData={questionsData}
            isInReanswerMode={isInReanswerMode}
            isDisabled={isDisabled}
          />
        )}
      </div>
    </div>
  )

  const Content = (
    <div {...(useSwipeHandlers ? swipeHandlers : {})}>
      <TitleBox
        question={question}
        questionsData={questionsData}
        topSection={
          !isInModal ? (
            <>
              {Header}
              <AppDivider size="small" />
            </>
          ) : null
        }
      />
      <AppDivider size="medium" />
      {question && (
        <>
          {displayVotingResults ? (
            <VotingResults question={question} />
          ) : (
            <SubmitForm
              question={question}
              isFetchingQuestion={isFetchingQuestion}
              isCreatingAnswer={isCreatingAnswer}
              isUpdatingAnswer={isUpdatingAnswer}
              isInReanswerMode={isInReanswerMode}
              setIsInReanswerMode={setIsInReanswerMode}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        </>
      )}
    </div>
  )

  return (
    <>
      <ReportQuestionModal questionId={questionId} />
      <DeleteQuestionModal questionId={questionId} />
      <TerminateQuestionModal questionId={questionId} refetchQuestion={refetchQuestion} />
      {isInModal ? (
        <AppModalDialog
          open={showQuestionModal}
          loading={isLoadingQuestion}
          size="large"
          onClose={handleClose}
          Header={Header}
          Content={Content}
          Actions={
            <AppGroup fullWidth alignment="horizontal" gapSize="small">
              <AppButton
                text={t('common.buttons.copyLink')}
                disabled={!question}
                iconName="copy"
                onClick={onCopyLinkBtnClick}
                size="small"
                variant="outlined"
              />
              <AppButton
                text={t('common.buttons.newTab')}
                disabled={!question}
                iconName="external"
                onClick={onOpenBtnClick}
                size="small"
                variant="outlined"
              />
              <AppButton
                variant="outlined"
                text={t('common.buttons.close')}
                onClick={handleClose}
                size="small"
              />
            </AppGroup>
          }
        />
      ) : (
        <AppLoader active={isLoadingQuestion}>{Content}</AppLoader>
      )}
    </>
  )
}

export default Question
