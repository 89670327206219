import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Sex } from 'atw-shared/utils'
import { AppFormRadio } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
}

const SexCheckbox = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name="sex"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormRadio
            label={t('common.sex.label')}
            options={[
              { value: Sex.Female, label: t('common.sex.female') },
              { value: Sex.Male, label: t('common.sex.male') },
            ]}
            currentValue={value}
            onChange={onChange}
            disabled={isLoading}
            error={String(errors?.sex?.message || '')}
          />
        )
      }}
    />
  )
}

export default SexCheckbox
