import { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Filter, IQuestion } from 'atw-shared/utils'
import { UserAvatar } from 'client/components'
import { humanizeFromToNow } from 'client/services'
import { useAppContext } from 'client/hooks'

import styles from './question-list-item.module.scss'
import { AppHeader, AppParagraph } from 'client/ui-building-blocks'

type Props = {
  activePage: number
  item: IQuestion
  isFetching: boolean
  onItemClick: () => void
}

const QuestionListItem = (props: Props) => {
  const { t, i18n } = useTranslation()

  const [displayCreatedAtInfo, setDisplayCreatedAtInfo] = useState<boolean>(true)
  const [displayTerminatedAtInfo, setDisplayTerminatedAtInfo] = useState<boolean>(false)

  const { authUser, browseQuestionsPageState } = useAppContext()

  const { tabKey } = browseQuestionsPageState

  const { item, onItemClick } = props
  const isTerminated = Boolean(authUser && item.terminatedAt)

  useLayoutEffect(() => {
    if (!props.isFetching) {
      setDisplayCreatedAtInfo(!item.requestorAnsweredAt && tabKey !== Filter.Terminated)
      setDisplayTerminatedAtInfo(
        item.terminatedAt && [Filter.Created, Filter.Terminated].includes(tabKey)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isFetching])

  return (
    <div className={styles.item} onClick={onItemClick}>
      <div>
        <div>
          <div>
            <AppHeader
              data-testid="title"
              text={item.text}
              decoration={isTerminated ? 'lineThrough' : 'none'}
            />
          </div>
          <div>
            <UserAvatar userId={item.creatorId.toString()} size="small" rounded />
          </div>
        </div>
        <div>
          {item.submittedTimes ? (
            <AppParagraph
              text={t('question.info.submittedTimes', {
                count: item.submittedTimes,
              })}
              color="secondary"
              size="small"
            />
          ) : null}
          {displayCreatedAtInfo && (
            <AppParagraph
              text={t('question.info.creationTimePassed', {
                timePassed: humanizeFromToNow(item.createdAt, i18n.language),
              })}
              color="secondary"
              size="small"
            />
          )}
          {item.requestorAnsweredAt ? (
            <AppParagraph
              text={t('question.info.submissionTimePassed', {
                timePassed: humanizeFromToNow(item.requestorAnsweredAt, i18n.language),
              })}
              color="secondary"
              size="small"
            />
          ) : null}
          {displayTerminatedAtInfo ? (
            <AppParagraph
              text={t('question.info.terminationTimePassed', {
                timePassed: humanizeFromToNow(item.terminatedAt, i18n.language),
              })}
              color="secondary"
              size="small"
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default QuestionListItem
