import { useTranslation } from 'react-i18next'

import { IQuestion } from 'atw-shared/utils'
import { AppParagraph } from 'client/ui-building-blocks'
import { humanizeFromToNow, humanizeDuration, formatTimestamp } from 'client/services'
import { TData as TQuestionsData } from 'client/api/useGetQuestions/useGetQuestions'

type Props = {
  question: IQuestion
  questionsData?: TQuestionsData
}

const Details = ({ question, questionsData }: Props) => {
  const { t, i18n } = useTranslation()

  const submittedTimes =
    question?.submittedTimes ||
    questionsData?.data.find(q => q._id.toString() === question?._id.toString())
      ?.submittedTimes

  return (
    <>
      <AppParagraph
        text={
          t('question.info.creationTimePassed', {
            timePassed: humanizeFromToNow(question?.createdAt, i18n.language),
          }) +
          ' ' +
          t('question.info.creationDateTime', {
            dateTime: formatTimestamp(question?.createdAt, i18n.language),
          })
        }
        size="small"
        color="secondary"
      />
      {question?.terminatedAt ? (
        <>
          <AppParagraph
            text={
              t('question.info.terminationTimePassed', {
                timePassed: humanizeFromToNow(question?.terminatedAt, i18n.language),
              }) +
              ' ' +
              t('question.info.terminationDateTime', {
                dateTime: formatTimestamp(question?.terminatedAt, i18n.language),
              })
            }
            size="small"
            color="secondary"
          />
          <AppParagraph
            text={t('question.info.liveFor', {
              time: humanizeDuration(
                question?.terminatedAt,
                question?.createdAt,
                i18n.language
              ),
            })}
            size="small"
            color="secondary"
          />
        </>
      ) : null}
      {submittedTimes !== undefined ? (
        <AppParagraph
          text={t('question.info.submittedTimes', {
            count: submittedTimes,
          })}
          size="small"
          color="secondary"
        />
      ) : null}
    </>
  )
}

export default Details
