import { useTranslation } from 'react-i18next'

import { AppHeader, AppImage, AppMessageBox } from 'client/ui-building-blocks'
import { IQuestion } from 'atw-shared/utils'
import { DEFAULT_THEME } from 'client/utils'
import { getAssetUrl, getLocalLastUserConfig } from 'client/services'
import { useAppContext } from 'client/hooks'

type Props = {
  question: IQuestion
}

const NumOfAnswersInfo = ({ question }: Props) => {
  const { t } = useTranslation()

  const { isInReanswerMode } = useAppContext()

  const {
    selectableOptions: { exact, range },
    canBeReanswered,
    answeredStats,
  } = question

  let content = ''

  if (isInReanswerMode || !answeredStats) {
    content += exact
      ? t('common.question.selectExactly', { count: exact })
      : t('common.question.selectBetween', { min: range.min, max: range.max })

    content += ', '

    content += canBeReanswered
      ? t('common.question.canBeReanswered')
      : t('common.question.cannotBeReanswered')
  }

  const currentTheme = getLocalLastUserConfig().theme || DEFAULT_THEME

  return (
    <AppMessageBox
      headerNode={<AppHeader text={content} decoration="underlined" size="small" />}
      imgNode={
        <AppImage
          src={getAssetUrl(`survey-submit-${currentTheme}.png`)}
          width="47px"
          height="45px"
        />
      }
    />
  )
}

export default NumOfAnswersInfo
