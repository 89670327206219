// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.URbMJ3umDfqrENHw0r2S{display:flex;align-items:center;min-height:40px}.URbMJ3umDfqrENHw0r2S>div:first-child{flex:1}`, "",{"version":3,"sources":["webpack://./src/client/components/Question/question.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,eAAA,CAGF,sCACE,MAAA","sourcesContent":[".topBar {\n  display: flex;\n  align-items: center;\n  min-height: 40px;\n}\n\n.topBar > div:first-child {\n  flex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topBar": `URbMJ3umDfqrENHw0r2S`
};
export default ___CSS_LOADER_EXPORT___;
