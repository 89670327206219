import { useTranslation } from 'react-i18next'

import { IQuestion } from 'atw-shared/utils'
import { useGetQuestionCategories } from 'client/api'
import { AppChip, AppGroup } from 'client/ui-building-blocks'

import styles from './categories.module.scss'

type Props = {
  question: IQuestion
}

const Categories = ({ question }: Props) => {
  const { i18n } = useTranslation()

  const { data: questionCategories } = useGetQuestionCategories({})

  return (
    <div className={styles.categoriesContainer}>
      {questionCategories && (
        <AppGroup gapSize="xsmall">
          {question?.categories?.map(categoryId => (
            <AppChip
              key={categoryId}
              text={
                questionCategories[i18n.language.toLowerCase()].find(
                  c => c.key === categoryId
                ).text
              }
              size="small"
            />
          ))}
        </AppGroup>
      )}
    </div>
  )
}

export default Categories
