import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { IQuestion } from 'atw-shared/utils'
import { Question } from 'client/components'
import { AppList } from 'client/ui-building-blocks'
import { useAppContext, useModalDialogContext, usePage } from 'client/hooks'
import { TData as TQuestionsData } from 'client/api/useGetQuestions/useGetQuestions'

import QuestionListItem from './question-list-item'

type Props = {
  questionsData: TQuestionsData
  isLoading: boolean
  isFetching: boolean
  title?: string
  pageSize: number
  activePage?: number
}

const QuestionsList = ({
  questionsData = { count: 0, data: [] },
  isLoading,
  isFetching,
  pageSize,
  title,
  activePage,
}: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { navigateTo } = usePage()

  const { listActiveQuestionId, setBrowseQuestionsPageState } = useAppContext()

  const { showQuestionModal, toggleQuestionModal, closeAllModals } =
    useModalDialogContext()

  useEffect(() => {
    if (listActiveQuestionId) {
      toggleQuestionModal(true)
    } else {
      closeAllModals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listActiveQuestionId])

  const onPageChange = (pageNo: number) => {
    if (!isLoading && !isFetching) {
      setBrowseQuestionsPageState(state => ({ ...state, pageNo }))
    }
  }

  const renderItem = (item: IQuestion) => {
    const onItemClick = () => {
      if (!isLoading && !isFetching) {
        navigateTo({
          path: pathname,
          query: { questionId: item._id.toString() },
          preventScroll: true,
        })
      }
    }

    return (
      <QuestionListItem
        key={item._id.toString()}
        activePage={activePage}
        item={item}
        isFetching={isLoading || isFetching}
        onItemClick={onItemClick}
      />
    )
  }

  return (
    <>
      <AppList
        data={questionsData}
        renderItem={renderItem}
        title={title}
        isLoading={isLoading}
        isFetching={isFetching}
        noItemsMsg={t('common.info.nothingToDisplay')}
        pageSize={pageSize}
        activePage={activePage}
        gridLayoutVariant="grid"
        onPageChange={onPageChange}
      />
      {showQuestionModal && (
        <Question
          questionId={listActiveQuestionId}
          questionsData={questionsData}
          isInModal
        />
      )}
    </>
  )
}

export default QuestionsList
