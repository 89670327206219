import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { USERNAME_MAX_LENGTH, USERNAME_MIN_LENGTH } from 'atw-shared/utils'
import { hasCredentialAllCharsValid } from 'atw-shared/validation'
import { ApiErrors } from 'client/utils'
import { AppFormInput } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
  apiErrors: ApiErrors
}

const NewUsernameInput = ({ isLoading, apiErrors }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error =
    errors?.username?.message ||
    (apiErrors?.username ? t(`apiValidationErrors.${apiErrors.username}`) : '')

  return (
    <Controller
      control={control}
      name="username"
      rules={{
        required: t('common.validation.required'),
        minLength: {
          value: USERNAME_MIN_LENGTH,
          message: t('common.validation.minLength', { min: USERNAME_MIN_LENGTH }),
        },
        maxLength: {
          value: USERNAME_MAX_LENGTH,
          message: t('common.validation.maxLength', { max: USERNAME_MAX_LENGTH }),
        },
        validate: value =>
          hasCredentialAllCharsValid(value) || t('common.validation.invalidChars'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormInput
            value={value}
            onChange={onChange}
            label={t('common.username.label')}
            placeholder={`${USERNAME_MIN_LENGTH} - ${USERNAME_MAX_LENGTH}`}
            maxLength={USERNAME_MAX_LENGTH}
            disabled={isLoading}
            error={String(error || '')}
          />
        )
      }}
    />
  )
}

export default NewUsernameInput
