import { useAppContext } from 'client/hooks'
import { AppButton } from 'client/ui-building-blocks'

type Props = {
  isSubmitting: boolean
  isLoadingQuestion: boolean
  isFetchingQuestion: boolean
}

const RandomNavigation = ({
  isSubmitting,
  isLoadingQuestion,
  isFetchingQuestion,
}: Props) => {
  const { isInReanswerMode, randomQuestionIndex, setRandomQuestionIndex } =
    useAppContext()

  const onNextClick = () => {
    if (!isFetchingQuestion) {
      setRandomQuestionIndex(randomQuestionIndex + 1)
    }
  }

  return (
    <AppButton
      data-testid="question-nav-next"
      id="question-nav-next"
      variant="outlined"
      size="small"
      iconName="forward"
      disabled={isLoadingQuestion || isSubmitting || isInReanswerMode}
      onClick={onNextClick}
    />
  )
}

export default RandomNavigation
