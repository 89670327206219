import { useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { displaySnackBar } from 'client/services'
import { useDeleteQuestion } from 'client/api'
import { useAppContext, useModalDialogContext, usePage } from 'client/hooks'

import {
  AppButton,
  AppGroup,
  AppHeader,
  AppModalDialog,
  AppParagraph,
} from 'client/ui-building-blocks'

type Props = {
  questionId: string
}

const DeleteQuestionModal = ({ questionId }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { navigateTo, goToCleanRoute } = usePage()
  const { setListActiveQuestionId } = useAppContext()
  const { showDeleteQuestionModal, toggleDeleteQuestionModal } = useModalDialogContext()

  const { mutateAsync: deleteQuestion, isLoading: isDeleting } = useDeleteQuestion({
    onSuccess: data => {
      setListActiveQuestionId(null)
      toggleDeleteQuestionModal(false)
      if (data?.msg) {
        displaySnackBar(data.msg)
      }
      setTimeout(() => {
        if (pathname === '/question') {
          navigateTo({ path: '/browse' })
        } else {
          goToCleanRoute(true)
        }
      })
    },
    onError: err => {
      setListActiveQuestionId(null)
      toggleDeleteQuestionModal(false)
      if (err?.msg) {
        displaySnackBar(err.msg)
      }
    },
  })

  if (!showDeleteQuestionModal) return null

  const onDelete = () => {
    deleteQuestion({
      questionId,
    })
  }

  const onCloseClick = () => {
    if (!queryClient.isFetching() && !queryClient.isMutating()) {
      toggleDeleteQuestionModal(false)
    }
  }

  return (
    <AppModalDialog
      open={showDeleteQuestionModal}
      onClose={onCloseClick}
      Header={
        <AppHeader
          text={t('app.modals.deleteQuestion.header')}
          iconProps={{ name: 'remove' }}
          dividing
        />
      }
      Content={<AppParagraph text={t('app.modals.deleteQuestion.description')} />}
      Actions={
        <AppGroup centered>
          <AppButton
            variant="warning"
            size="small"
            text={t('common.buttons.delete')}
            loading={isDeleting}
            onClick={onDelete}
          />
          <AppButton
            variant="outlined"
            size="small"
            text={t('common.buttons.cancel')}
            disabled={isDeleting}
            onClick={onCloseClick}
          />
        </AppGroup>
      }
    />
  )
}

export default DeleteQuestionModal
