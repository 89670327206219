// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mU45_Vs7VGv2T1zFGHTC .BarY21enwnQjZnnJPbr0{display:flex;align-items:center;flex-flow:row nowrap}.mU45_Vs7VGv2T1zFGHTC .BarY21enwnQjZnnJPbr0 .qnRW6exBGrhUocJLIBQ6{flex:1}.mU45_Vs7VGv2T1zFGHTC .BarY21enwnQjZnnJPbr0 .p9HUs13gYeG8NwglW3Ex{text-align:right;margin-left:28px}`, "",{"version":3,"sources":["webpack://./src/client/components/Question/TitleBox/title-box.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,kBAAA,CACA,oBAAA,CAEA,kEACE,MAAA,CAGF,kEACE,gBAAA,CACA,gBAAA","sourcesContent":[".titleBox {\n  .flex {\n    display: flex;\n    align-items: center;\n    flex-flow: row nowrap;\n\n    .textContainer {\n      flex: 1;\n    }\n\n    .avatarContainer {\n      text-align: right;\n      margin-left: 28px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleBox": `mU45_Vs7VGv2T1zFGHTC`,
	"flex": `BarY21enwnQjZnnJPbr0`,
	"textContainer": `qnRW6exBGrhUocJLIBQ6`,
	"avatarContainer": `p9HUs13gYeG8NwglW3Ex`
};
export default ___CSS_LOADER_EXPORT___;
