import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import { Filter, IQuestion } from 'atw-shared/utils'
import { AppContextMenu } from 'client/ui-building-blocks'
import { getAccountStatus, isAccountFull } from 'client/services'
import { useAppContext, useModalDialogContext } from 'client/hooks'

type Props = {
  question: IQuestion
  isInReanswerMode: boolean
  setIsInReanswerMode: (isInReanswerMode: boolean) => void
  isDisabled: boolean
}

const ContextMenu = ({
  question,
  isInReanswerMode,
  setIsInReanswerMode,
  isDisabled,
}: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const {
    toggleReportQuestionModal,
    toggleTerminateQuestionModal,
    toggleDeleteQuestionModal,
  } = useModalDialogContext()

  const { authUser, browseQuestionsPageState } = useAppContext()
  const accountStatus = getAccountStatus(authUser)

  let isDeletable = false
  let isTerminatable = false
  let enableTerminateBtn = false
  let isReportable = false
  let isReanswerable = false

  if (authUser && question) {
    if (isAccountFull(accountStatus)) {
      isDeletable =
        authUser._id === question.creatorId &&
        pathname === '/browse' &&
        browseQuestionsPageState.tabKey === Filter.Created
      isTerminatable =
        authUser._id === question.creatorId &&
        !question.terminatedAt &&
        pathname === '/browse' &&
        browseQuestionsPageState.tabKey === Filter.Created
      isReportable = authUser._id !== question.creatorId
    }

    isReanswerable =
      question.canBeReanswered &&
      !question.terminatedAt &&
      question.answeredStats &&
      !isEmpty(question.answeredStats)

    enableTerminateBtn =
      !question.terminatedAt && question.answeredStats && !isEmpty(question.answeredStats)
  }

  const menuItems = [
    {
      label: t('question.contextMenu.items.reanswer'),
      onClick: () => {
        if (isReanswerable) {
          setIsInReanswerMode(!isInReanswerMode)
        }
      },
      hide: !isReanswerable || isInReanswerMode,
    },
    {
      label: t('question.contextMenu.items.report'),
      onClick: () => {
        if (isReportable) {
          toggleReportQuestionModal(true)
        }
      },
      hide: !isReportable,
    },
    {
      label: t('question.contextMenu.items.terminate'),
      onClick: () => {
        if (isTerminatable) {
          toggleTerminateQuestionModal(true)
        }
      },
      hide: !isTerminatable,
      disabled: !enableTerminateBtn,
    },
    {
      label: t('question.contextMenu.items.delete'),
      onClick: () => {
        if (isDeletable) {
          toggleDeleteQuestionModal(true)
        }
      },
      hide: !isDeletable,
    },
  ]

  return <AppContextMenu isDisabled={isDisabled || isInReanswerMode} items={menuItems} />
}

export default ContextMenu
