import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import { ApiUrlPath, IQuestion } from 'atw-shared/utils'
import { AppButton } from 'client/ui-building-blocks'
import { useAppContext, usePage } from 'client/hooks'
import { displaySnackBar } from 'client/services'
import { useFollowQuestion, useUnfollowQuestion } from 'client/api'
import { TData as TGetQuestionData } from 'client/api/useGetQuestion/useGetQuestion'

type Props = {
  question: IQuestion
}

const ActionBtns = ({ question }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()

  const { navigateTo } = usePage()
  const { authUser } = useAppContext()

  const { mutateAsync: followQuestion, isLoading: isLoadingFollowQuestion } =
    useFollowQuestion()

  const { mutateAsync: unfollowQuestion, isLoading: isLoadingUnfollowQuestion } =
    useUnfollowQuestion()

  const onFollowBtnClick = () => {
    if (isFollowable) {
      if (!question.isRequestorFollowing) {
        followQuestion({ questionId: String(question?._id) })
          .then(() => {
            queryClient.setQueryData(
              [ApiUrlPath.GetQuestion, question?._id.toString()],
              (data: TGetQuestionData) => {
                return {
                  ...data,
                  data: [{ ...data.data[0], isRequestorFollowing: true }],
                }
              }
            )
          })
          .catch(err => {
            if (err?.msg) {
              displaySnackBar(err.msg)
              handleClose()
            }
          })
      } else {
        unfollowQuestion({ questionId: String(question?._id) })
          .then(() => {
            queryClient.setQueryData(
              [ApiUrlPath.GetQuestion, question?._id.toString()],
              (data: TGetQuestionData) => {
                return {
                  ...data,
                  data: [{ ...data.data[0], isRequestorFollowing: false }],
                }
              }
            )
          })
          .catch(err => {
            if (err?.msg) {
              displaySnackBar(err.msg)
              handleClose()
            }
          })
      }
    }
  }

  const handleClose = () => {
    navigateTo({ path: pathname, preventScroll: true })
  }

  const isFollowable =
    authUser && authUser.config?.confirmed && authUser._id !== question?.creatorId

  return (
    <>
      {isFollowable && (
        <AppButton
          text={
            question?.isRequestorFollowing
              ? t('question.buttons.unfollow')
              : t('question.buttons.follow')
          }
          iconName={question?.isRequestorFollowing ? 'checked' : 'follow'}
          iconPosition={question?.isRequestorFollowing ? 'right' : 'left'}
          loading={isLoadingFollowQuestion || isLoadingUnfollowQuestion}
          disabled={!question}
          onClick={onFollowBtnClick}
          size="small"
          variant="outlined"
          position="right"
        />
      )}
    </>
  )
}

export default ActionBtns
