import { ReactNode } from 'react'

import { AccountStatus, IQuestion } from 'atw-shared/utils'
import { getAccountStatus } from 'client/services'
import { useAppContext } from 'client/hooks'
import { UserAvatar } from 'client/components'
import { TData as TQuestionsData } from 'client/api/useGetQuestions/useGetQuestions'
import { AppDivider, AppHeader } from 'client/ui-building-blocks'

import Categories from '../Categories/categories'
import Details from '../Details/details'
import ActionBtns from '../ActionBtns/action-btns'

import styles from './title-box.module.scss'

type Props = {
  question: IQuestion
  questionsData?: TQuestionsData
  topSection?: ReactNode
}

const TitleBox = ({ question, questionsData, topSection = null }: Props) => {
  const { authUser } = useAppContext()

  const accountStatus = getAccountStatus(authUser)

  const displayAsTerminated = authUser && question?.terminatedAt

  const onAvatarClick = () => {
    window.open('/profile?_id=' + question.creatorId, '_blank')
  }

  return (
    <div className={styles.titleBox}>
      {topSection}
      <div className={styles.flex}>
        <div className={styles.textContainer}>
          {question && (
            <AppHeader
              text={question?.text}
              decoration={displayAsTerminated ? 'lineThrough' : 'none'}
              size="large"
            />
          )}
        </div>
        <div className={styles.avatarContainer}>
          <UserAvatar
            userId={question?.creatorId?.toString()}
            onClick={accountStatus !== AccountStatus.UNKNOWN && onAvatarClick}
          />
        </div>
      </div>
      <AppDivider size="small" />
      <Categories question={question} />
      {question && question._id ? (
        <>
          <AppDivider border size="small" />
          <Details question={question} questionsData={questionsData} />
          <AppDivider size="small" />
          <ActionBtns question={question} />
        </>
      ) : null}
    </div>
  )
}

export default TitleBox
