import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountStatus } from 'atw-shared/utils'
import { useAppContext, useModalDialogContext } from 'client/hooks'
import { getAccountStatus } from 'client/services'

import {
  AppButton,
  AppHeader,
  AppModalDialog,
  AppParagraph,
} from 'client/ui-building-blocks'

const NoAccessModal = () => {
  const { t } = useTranslation()

  const { authUser } = useAppContext()
  const accountStatus = getAccountStatus(authUser)

  const { showNoAccessModal, toggleNoAccessModal } = useModalDialogContext()

  const MSG_TEXT = useMemo(() => {
    return {
      [AccountStatus.UNKNOWN]: t(
        'app.modals.noAccess.description.actionNotAllowedReasonOne'
      ),
      [AccountStatus.CREATED]: t(
        'app.modals.noAccess.description.actionNotAllowedReasonTwo'
      ),
      [AccountStatus.CONFIRMED]: t(
        'app.modals.noAccess.description.actionNotAllowedReasonThree'
      ),
    }
  }, [t])

  const onClose = () => {
    toggleNoAccessModal(false)
  }

  return (
    <AppModalDialog
      open={showNoAccessModal}
      onClose={onClose}
      Header={
        <AppHeader
          text={t('app.modals.noAccess.header')}
          iconProps={{ name: 'password' }}
          dividing
        />
      }
      Content={<AppParagraph text={MSG_TEXT[accountStatus]} />}
      Actions={
        <AppButton
          variant="outlined"
          size="small"
          text={t('common.buttons.close')}
          onClick={onClose}
          position="center"
        />
      }
    />
  )
}

export default NoAccessModal
