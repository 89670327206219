import { PropsWithChildren } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { MemoryRouter } from 'react-router'

export const WithQueryClientProvider = ({ children }: PropsWithChildren) => {
  const queryClient = new QueryClient()
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export const WithFormProvider = ({ children }: PropsWithChildren) => {
  const form = useForm()
  return <FormProvider {...form}>{children}</FormProvider>
}

export const WithRouter = ({ children }: PropsWithChildren) => {
  return <MemoryRouter>{children}</MemoryRouter>
}
