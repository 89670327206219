import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'

import { useGetCountries } from 'client/api'
import { displaySnackBar } from 'client/services'
import { AppFormAutoComplete } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
}

const CountryAutocomplete = ({ isLoading }: Props) => {
  const { t, i18n } = useTranslation()

  const {
    control,
    formState: { errors },
    setError,
  } = useFormContext()

  const { data: countries, isLoading: isLoadingCountries } = useGetCountries({
    onError: err => {
      if (err?.msg) {
        setError('country', {
          message: t(`apiMsgs.${err.msg.code}`),
        })
        displaySnackBar(err.msg)
      }
    },
  })

  return (
    <Controller
      control={control}
      name="country"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppFormAutoComplete
            label={t('common.country.label')}
            placeholder={t('common.country.placeholder')}
            value={value}
            options={countries ? countries[i18n.language.toLowerCase()] : []}
            disabled={isLoadingCountries || isLoading}
            error={String(errors.country?.message || '')}
            onChange={onChange}
          />
        )
      }}
    />
  )
}

export default CountryAutocomplete
