/* eslint-disable indent */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller, UseFormReset } from 'react-hook-form'
import values from 'lodash/values'

import { IQuestion, QuestionType } from 'atw-shared/utils'
import { QuestionSubmitFormValues } from 'client/utils'
import { getSubmitQuestionInitialValues } from 'client/services'
import { useModalDialogContext } from 'client/hooks'
import { fixScrollPosition } from 'client/helpers'
import { AppButton, AppDivider, AppGroup } from 'client/ui-building-blocks'
import { Form } from 'client/semantic-imports'

import NumOfAnswersInfo from '../NumOfAnswersInfo/num-of-answers-info'
import AnswersListItem from '../AnswersListItem/answers-list-item'

type Props = {
  question: IQuestion
  isFetchingQuestion: boolean
  isCreatingAnswer: boolean
  isUpdatingAnswer: boolean
  isInReanswerMode: boolean
  setIsInReanswerMode: (isInReanswerMode: boolean) => void
  isSubmitting: boolean
  onSubmit: (
    formValues: QuestionSubmitFormValues,
    reset: UseFormReset<QuestionSubmitFormValues>
  ) => Promise<unknown>
  previewMode?: boolean
}

const SubmitForm = ({
  question,
  isFetchingQuestion,
  isCreatingAnswer,
  isUpdatingAnswer,
  isInReanswerMode,
  setIsInReanswerMode,
  isSubmitting,
  onSubmit,
  previewMode,
}: Props) => {
  const { t } = useTranslation()

  const { showQuestionModal } = useModalDialogContext()

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      votes: {},
    },
  })

  useEffect(() => {
    const values = getSubmitQuestionInitialValues(question)
    reset(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question._id])

  const onCancelClick = () => {
    setIsInReanswerMode(false)
    fixScrollPosition(showQuestionModal)
  }

  const onResetClick = () => {
    reset()
  }

  const {
    options,
    selectableOptions: { exact, range },
  } = question

  const votes = watch('votes')
  const selectedAnswersCount = values(votes).filter(selected => selected).length

  const missingAnswersNo = exact
    ? exact - selectedAnswersCount
    : range.min - selectedAnswersCount

  const isAnswerListItemDisabled =
    isFetchingQuestion || isCreatingAnswer || isUpdatingAnswer

  const isSubmitBtnDisabled =
    !isSubmitting && (!isValid || !isDirty || missingAnswersNo > 0)

  const isResetBtnDisabled = isAnswerListItemDisabled || !isValid || !isDirty

  return (
    <>
      <NumOfAnswersInfo question={question} />
      <AppDivider size="medium" />
      <Form
        onSubmit={handleSubmit(data => {
          return onSubmit(data, reset)
        })}
      >
        <Controller
          control={control}
          name="votes"
          rules={{
            required: t('common.validation.required'),
          }}
          render={({ field: { value: votes, onChange } }) => {
            return (
              <>
                {options.map((answer, i) => {
                  const onClick = () => {
                    // selecting not selected
                    if (!votes[i]) {
                      const selectedCount = values(votes).filter(
                        selected => selected
                      ).length
                      if (
                        (exact && selectedCount === exact) ||
                        (!exact && selectedCount === range.max)
                      ) {
                        const lastSelectedIndex = values(votes).lastIndexOf(true)
                        if (lastSelectedIndex !== -1) {
                          votes[lastSelectedIndex] = false
                        }
                      }
                      votes[i] = true

                      // deselecting
                    } else {
                      votes[i] = false
                    }
                    onChange({ target: { value: votes } })
                  }

                  return (
                    <AnswersListItem
                      key={question._id?.toString() + '_' + i}
                      rating={question.type === QuestionType.Rating}
                      last={i === options.length - 1}
                      disabled={isAnswerListItemDisabled}
                      marked={Boolean(votes[i])}
                      editMode
                      highlightOnHover
                      content={answer}
                      checkBox={{
                        value: i,
                        checked: Boolean(votes[i]),
                      }}
                      onClick={onClick}
                    />
                  )
                })}
              </>
            )
          }}
        />
        {!previewMode && (
          <>
            <AppDivider size="large" />
            <AppGroup fullWidth="mobile" alignment="verticalMobile" centered>
              <AppButton
                text={t('common.buttons.submit')}
                disabled={isSubmitBtnDisabled}
                loading={isSubmitting}
                type="submit"
              />
              <AppButton
                variant="outlined"
                text={t('common.buttons.reset')}
                disabled={isResetBtnDisabled}
                onClick={onResetClick}
                type="reset"
                id="submit-form-reset-btn"
              />
              {isInReanswerMode && (
                <AppButton
                  variant="outlined"
                  text={t('common.buttons.cancel')}
                  disabled={isAnswerListItemDisabled}
                  onClick={onCancelClick}
                />
              )}
            </AppGroup>
            <AppDivider size="large" />
          </>
        )}
      </Form>
    </>
  )
}

export default SubmitForm
