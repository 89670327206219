import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import values from 'lodash/values'

import { ReportReason } from 'atw-shared/utils'
import { displaySnackBar } from 'client/services'
import { useCreateReport } from 'client/api'
import { useModalDialogContext } from 'client/hooks'
import { Form } from 'client/semantic-imports'

import {
  AppButton,
  AppGroup,
  AppDivider,
  AppHeader,
  AppModalDialog,
  AppParagraph,
  AppFormCheckBox,
} from 'client/ui-building-blocks'

type Props = {
  questionId: string
}

type FormValues = {
  reasons: number[]
}

const ReportQuestionModal = ({ questionId }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { showReportQuestionModal, toggleReportQuestionModal, toggleQuestionModal } =
    useModalDialogContext()

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<FormValues, undefined, FormValues>({
    defaultValues: {
      reasons: [],
    },
  })

  const { mutateAsync: createReport, isLoading: isCreatingReport } = useCreateReport()

  useEffect(() => {
    if (!showReportQuestionModal) {
      reset()
    }
  }, [showReportQuestionModal, reset])

  const onClose = () => {
    if (!queryClient.isFetching() && !queryClient.isMutating()) {
      toggleReportQuestionModal(false)
    }
  }

  const onReportClick = handleSubmit(async ({ reasons }) => {
    return createReport({
      questionId,
      reasons: reasons.map(i => reportReasonKeys[i]),
    })
      .then(data => {
        toggleReportQuestionModal(false)
        if (data?.msg) {
          displaySnackBar(data.msg)
        }
      })
      .catch(err => {
        toggleReportQuestionModal(false)
        toggleQuestionModal(false)
        if (err?.msg) {
          displaySnackBar(err.msg)
        }
      })
  })

  if (!showReportQuestionModal) return null

  const reportReasonKeys = values(ReportReason)

  const options = reportReasonKeys.map((k, i) => ({
    value: i,
    label: t('app.modals.reportQuestion.reasons', {
      returnObjects: true,
    })[k],
  }))

  return (
    <AppModalDialog
      open={showReportQuestionModal}
      Header={
        <AppHeader
          text={t('app.modals.reportQuestion.header')}
          iconProps={{ name: 'bug' }}
          dividing
        />
      }
      Content={
        <>
          <AppParagraph text={t('app.modals.reportQuestion.description')} />
          <AppDivider />
          <Form>
            <Controller
              control={control}
              name="reasons"
              rules={{
                required: true,
              }}
              render={({ field: { value: reasons, onChange } }) => {
                return (
                  <AppFormCheckBox
                    currentValues={reasons}
                    options={options}
                    onChange={newCurrentValues => {
                      onChange({ target: { value: newCurrentValues } })
                    }}
                    disabled={isCreatingReport}
                  />
                )
              }}
            />
          </Form>
        </>
      }
      Actions={
        <AppGroup centered>
          <AppButton
            variant="warning"
            size="small"
            text={t('common.buttons.report')}
            loading={isCreatingReport}
            disabled={Boolean(errors.reasons) || !isDirty}
            onClick={onReportClick}
            type="submit"
          />
          <AppButton
            variant="outlined"
            size="small"
            text={t('common.buttons.cancel')}
            disabled={isCreatingReport}
            onClick={onClose}
          />
        </AppGroup>
      }
      onClose={onClose}
    />
  )
}

export default ReportQuestionModal
