import { AppBlobImage, AppPopup, Size } from 'client/ui-building-blocks'
import { getUserAvatarUrl, getAssetUrl } from 'client/services'
import { useEnvContext } from 'client/hooks'

type Props = {
  userId: string
  size?: Size
  rounded?: boolean
  popupContent?: string
  onClick?: () => void
}

const UserAvatar = ({
  userId,
  size = 'medium',
  rounded = true,
  popupContent,
  onClick,
}: Props) => {
  const { awsBucketUrl } = useEnvContext()

  return (
    <AppPopup text={popupContent}>
      <AppBlobImage
        size={size}
        rounded={rounded}
        src={getUserAvatarUrl(awsBucketUrl, userId)}
        defaultImg={getAssetUrl('avatar-default.png')}
        onClick={onClick}
      />
    </AppPopup>
  )
}

export default UserAvatar
