import { IQuestion, QuestionType } from 'atw-shared/utils'

import AnswersListItem from '../AnswersListItem/answers-list-item'

type Props = {
  question: IQuestion
}

const VotingResults = ({ question }: Props) => {
  const totalVotesNum = question.options.reduce(
    (acc, current, i) => (acc += question.answeredStats?.all[i]),
    0
  )

  const statsData = question.options.map((option, i) => {
    const numOfVotes = question.answeredStats?.all[i]
    const percentage = Number(((numOfVotes / totalVotesNum) * 100).toFixed(1))

    return {
      no: Number(i + 1),
      votes: numOfVotes,
      percentage,
      didAuthUserVote: question.answeredStats?.byRequestor.includes(i),
    }
  })

  const average =
    question.type === QuestionType.Rating
      ? (
          statsData
            .filter(item => item.votes > 0)
            .reduce((acc, current) => (acc += current.no * current.votes), 0) /
          totalVotesNum
        )
          .toFixed(1)
          .toString()
      : ''

  return (
    <>
      {statsData
        .sort((a, b) => b.votes - a.votes)
        .map((item, i) => {
          const votesCount = item.votes > 0 ? `${item.votes}x` : '-'

          return (
            <AnswersListItem
              key={i}
              rating={question.type === QuestionType.Rating}
              last={i === statsData.length - 1}
              marked={i < statsData.length && item.didAuthUserVote}
              content={question.options[item.no - 1]}
              count={{
                item: votesCount,
                percentage: statsData[i].percentage,
                total: (totalVotesNum || 0) + 'x',
                average,
              }}
            />
          )
        })}
    </>
  )
}

export default VotingResults
