import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Form } from 'client/semantic-imports'
import {
  AppChip,
  AppDivider,
  AppGroup,
  AppHeader,
  AppParagraph,
} from 'client/ui-building-blocks'

import styles from './answers-list-item.module.scss'

type Props = {
  rating?: boolean
  last?: boolean
  disabled?: boolean
  marked?: boolean
  highlightOnHover?: boolean
  editMode?: boolean
  content: string
  checkBox?: {
    value: number
    checked: boolean
  }
  count?: {
    item: string
    percentage: number
    total: string
    average?: string
  }
  onClick?: () => void
}

const AnswersListItem = ({
  rating,
  last,
  disabled,
  marked,
  highlightOnHover,
  editMode,
  content,
  checkBox,
  count,
  onClick,
}: Props) => {
  const { t } = useTranslation()

  const onItemClick = () => {
    if (disabled) {
      return
    }
    onClick?.()
  }
  return (
    <>
      <div
        className={classNames(
          styles.table,
          highlightOnHover && !disabled && styles.tableHovered,
          marked && styles.marked,
          editMode && styles.editMode,
          !disabled && onClick ? styles.pointer : undefined
        )}
        onClick={onItemClick}
      >
        <div
          className={styles.background}
          style={{ width: (count?.percentage || 0) + '%' }}
        />
        <div className={styles.cellsContainer}>
          {checkBox && (
            <div className={styles.leftCell}>
              <Form.Checkbox
                className={styles.checkBox}
                disabled={disabled}
                {...checkBox}
                onClick={e => {
                  e.stopPropagation()
                  onItemClick?.()
                }}
              />
            </div>
          )}
          <div className={styles.contentCell}>
            <AppHeader text={content} />
          </div>
        </div>
        {count?.percentage ? (
          <div>
            <AppDivider size="xsmall" />
            <AppGroup gapSize="xsmall">
              <AppChip text={count.percentage + '%'} size="small" />
              <AppChip text={count.item} size="small" />
            </AppGroup>
          </div>
        ) : null}
      </div>
      <AppDivider size="xsmall" />
      {count && last ? (
        <>
          <AppDivider size="small" />
          <div className={classNames(styles.table, styles.summary)}>
            <div className={styles.cellsContainer}>
              <div className={styles.contentCell}>
                <AppParagraph text={t('question.content.total')} size="small" />
              </div>
              <div className={styles.rightCell}>
                <AppParagraph text={count.total} size="small" />
              </div>
            </div>
          </div>
          {rating && (
            <div className={classNames(styles.table, styles.summary)}>
              <div className={styles.cellsContainer}>
                <div className={styles.contentCell}>
                  <AppParagraph text={t('question.content.average')} size="small" />
                </div>
                <div className={styles.rightCell}>
                  <AppParagraph text={count.average} size="small" />
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  )
}

export default AnswersListItem
