import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { displaySnackBar } from 'client/services'
import { useTerminateQuestion } from 'client/api'
import { useModalDialogContext } from 'client/hooks'

import {
  AppButton,
  AppGroup,
  AppHeader,
  AppModalDialog,
  AppParagraph,
} from 'client/ui-building-blocks'

type Props = {
  questionId: string
  refetchQuestion: () => void
}

const TerminateQuestionModal = ({ questionId, refetchQuestion }: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { showTerminateQuestionModal, toggleTerminateQuestionModal } =
    useModalDialogContext()

  const { mutateAsync: terminateQuestion, isLoading: isTerminating } =
    useTerminateQuestion({
      onSuccess: data => {
        toggleTerminateQuestionModal(false)
        if (data?.msg) {
          displaySnackBar(data.msg)
        }
        refetchQuestion()
      },
      onError: err => {
        toggleTerminateQuestionModal(false)
        if (err?.msg) {
          displaySnackBar(err.msg)
        }
      },
    })

  if (!showTerminateQuestionModal) return null

  const onTerminate = () => {
    terminateQuestion({
      questionId,
    }).catch(() => null)
  }

  const onCloseClick = () => {
    if (!queryClient.isFetching() && !queryClient.isMutating()) {
      toggleTerminateQuestionModal(false)
    }
  }

  return (
    <AppModalDialog
      open={showTerminateQuestionModal}
      onClose={onCloseClick}
      Header={
        <AppHeader
          text={t('app.modals.terminateQuestion.header')}
          iconProps={{ name: 'terminated' }}
          dividing
        />
      }
      Content={<AppParagraph text={t('app.modals.terminateQuestion.description')} />}
      Actions={
        <AppGroup centered>
          <AppButton
            variant="warning"
            size="small"
            loading={isTerminating}
            text={t('common.buttons.terminate')}
            onClick={onTerminate}
          />
          <AppButton
            variant="outlined"
            size="small"
            disabled={isTerminating}
            text={t('common.buttons.cancel')}
            onClick={onCloseClick}
          />
        </AppGroup>
      }
    />
  )
}

export default TerminateQuestionModal
