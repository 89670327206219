// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TZGjP4zSd2VGcFayfKNp{min-height:40px}`, "",{"version":3,"sources":["webpack://./src/client/components/Question/Categories/categories.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA","sourcesContent":[".categoriesContainer {\n  min-height: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"categoriesContainer": `TZGjP4zSd2VGcFayfKNp`
};
export default ___CSS_LOADER_EXPORT___;
