import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { USER_MIN_DATE_OF_BIRTH } from 'atw-shared/utils'
import { AppDatePicker } from 'client/ui-building-blocks'

type Props = {
  isLoading: boolean
}

const DateOfBirthPicker = ({ isLoading }: Props) => {
  const { t } = useTranslation()

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name="dateOfBirth"
      rules={{
        required: t('common.validation.required'),
      }}
      render={({ field: { value, onChange } }) => {
        return (
          <AppDatePicker
            label={t('common.dateOfBirth.label')}
            minDate={new Date(USER_MIN_DATE_OF_BIRTH)}
            value={value}
            onChange={onChange}
            disabled={isLoading}
            error={String(errors?.dateOfBirth?.message || '')}
          />
        )
      }}
    />
  )
}

export default DateOfBirthPicker
